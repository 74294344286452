interface TranslationsInterface {
  imageBy: string
  videoBy: string
  reshet: string
  tags: string
}

const translations: TranslationsInterface = {
  imageBy: 'צילום תמונה:',
  videoBy: 'צילום וידאו:',
  reshet: 'רשת 13',
  tags: 'תגיות:',
}

export default translations
