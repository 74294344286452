export const he = {
  season: 'עונה',
  yesterday: 'אתמול',
  toAllNewsflashes: 'לכל המבזקים',
  nowLive: 'עכשיו בערוץ 13: ',
  sections: 'מדורים',
  search: 'חיפוש',
  channel26: 'ערוץ 26',
  copyright: '© כל הזכויות שמורות לחברת רשת מדיה בע״מ',
  photoBy: 'צילום',
  newsflashes: 'מבזקים',
  todayAtReshet13: 'היום ברשת 13',
  fullProgramsSchedule: 'לוח השידורים המלא',
  today: 'היום',
  tomorrow: 'מחר',
  logoAlt: 'ערוץ רשת 13, כל תוכניות הטלוויזיה, החדשות והכתבות לצפייה ישירה',
  notFoundTitle: 'העמוד שחיפשתם לא נמצא',
  notFoundDescription:
    'יכול להיות שהוא נמחק, עבר שינוי או זמנית לא פעיל. בינתיים, אתם יכולים להיכנס לכתבות אחרות באתר שלנו.',
  notFoundButton: 'לעמוד הבית',
  watchingTime: 'זמן צפייה',
  broadcastDate: 'שודר בתאריך',
  vodMainButton: 'לצפייה',
  vodMainLink: 'לעמוד התוכנית',
  comments: 'תגובות',
  all: 'הכל',
  toProgramPage: 'לאתר התכנית',
  showMore: 'הצג עוד',
  showLess: 'הצג פחות',
  airDate: 'שודר בתאריך',
  lastEpisodes: 'פרקים אחרונים',
  nowPlaying: 'הפרק מתנגן',
  nowPlayingOtt: 'מתנגן עכשיו',
  onNow: 'משודר עכשיו',
  tags: 'תגיות',
  opinionsBold: 'דעה',
  opinionsRegular: ' אחת ביום',
  skip: 'דלג',
  exit: 'יציאה',
  loggIn: 'התחבר',
  oops: 'אופס משהו השתבש',
  days: 'ימים',
  hours: 'שעות',
  minutes: 'דקות',
  seconds: 'שניות',
  liveBroadcast: 'שידור חי',
  allGenre: 'כל התוכניות',
  similarShows: 'תוכניות דומות',
  site_name: 'רשת 13',
  noResults: 'לא מצאנו תוצאות ל',
  categories: 'קטגוריות',
  mostPopular: 'הכי נקראות',
  ad: 'פרסומת',
}
