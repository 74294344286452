import { NextRouter } from 'next/router'
import { utils } from '../components/Player/Helpers/utils'
import { colors } from '../styles/GlobalVariables'
import { clientConfig } from '../client/clientConfig'
import { config } from '../lib'
import { ILevels } from '../components/Player/Models/PlayerModels'

const pagesTypeRefresh = [
  'hp',
  'item',
  'recepie',
  'live',
  'tag',
  'writer',
  'category',
  'grid',
  'mivzakim',
  'tvguide',
  '404',
  'live_26',
]

export const animationDelayMs = {
  sevenMinutes: 7 * 60 * 1000,
  sevenSeconds: 7 * 1000,
}

export const getCreditString = (
  imageDescription: string,
  imageCredit: string,
  video_credit: string
) => {
  //? Reshet's code
  let res = ''
  const credit = [imageCredit, video_credit]
    .filter(str => str !== '')
    .join(', ')

  res += imageDescription ?? ''
  return res + (credit ? ' (' + credit + ')' : '')
}

export const checkPageTypeRefresh = (pageType: string) => {
  return pagesTypeRefresh.includes(pageType)
}

let reloadPageTimeout: NodeJS.Timeout
export const reloadPageSetPageViewed = (
  pageType: string,
  videoPlaying: boolean,
  router: NextRouter,
  notFound: boolean,
  is_auto_refresh: boolean
) => {
  reloadPageTimeout && clearTimeout(reloadPageTimeout)
  if (checkPageTypeRefresh(pageType) && is_auto_refresh) {
    //&& !disableRefresh
    const tout = utils.getParameterByName('refresh')

    reloadPageTimeout = setTimeout(
      videoPlaying => {
        if (!videoPlaying) {
          router.reload()
        }
      },
      tout ? parseInt(tout, 10) * 60 * 1000 : animationDelayMs.sevenMinutes,
      videoPlaying
    )
  }

  utils.setPageViewedInDay()
  return () => clearTimeout(reloadPageTimeout)
}

export const getRainbowBorderImage = (
  leftTextWidth: number | null,
  isMobile: boolean
) => {
  const firstSection = isMobile
    ? `${!leftTextWidth || leftTextWidth < 102 ? 102 : leftTextWidth}px`
    : '130px'
  const colorWidth = isMobile ? '11%' : '85px'

  const { darkBlue, pink, yellow, green } = colors

  return {
    background: `linear-gradient(
                110deg,
                ${darkBlue} 0px,
                ${darkBlue} ${firstSection},
                ${pink} ${firstSection},
                ${pink} calc(${firstSection} + ${colorWidth}),
                ${yellow} calc(${firstSection} + ${colorWidth}),
                ${yellow} calc(${firstSection} + ${colorWidth} * 2),
                ${green} calc(${firstSection} + ${colorWidth} * 2),
                ${green} calc(${firstSection} + ${colorWidth} * 3),
                ${darkBlue} calc(${firstSection} + ${colorWidth} * 3),
                ${darkBlue} 100%)`,
  }
}

export const pad = (str: string | number, width?: number, z?: string) => {
  width = width || 2
  z = z || '0'
  str = `${str}`
  if (str.length >= width) {
    return str
  }
  return new Array(width - str.length + 1).join(z) + str
}

export const isInternalUrl = (url?: string) => {
  if (url?.startsWith('/')) {
    return true
  }
  if (typeof window !== 'undefined') {
    return url?.startsWith(clientConfig.baseUrl)
  }
  return url?.startsWith(config.baseUrl)
}

export const generateHeadLevelsString = (levels: ILevels, isItem: boolean) => {
  if (levels) {
    const levels_str = JSON.stringify(levels)
    if (!levels_str) {
      return ''
    }
    const levels_parsed = JSON.parse(levels_str)
    const levelsArr: string[] = []
    for (const level in levels_parsed) {
      if (level.includes('_eng') && !!levels_parsed[level]) {
        levelsArr.push(levels_parsed[level])
      }
    }
    if (isItem) {
      levelsArr.splice(-1)
    }
    return levelsArr.join(',')
  }
  return ''
}

interface IkeyVal {
  key: string
  val: string | number
}

type IkeyValArr = IkeyVal[]
export const addUrlParams = (url: string, keyValArr: IkeyValArr) => {
  const tempUrl = url.split('?')
  tempUrl[1] = keyValArr.reduce((accumulator, currentValue) => {
    accumulator = accumulator || ''
    const joiner = accumulator.length === 0 ? '' : '&'
    return `${accumulator}${joiner}${currentValue.key}=${currentValue.val}`
  }, tempUrl[1])

  return tempUrl.join('?')
}
