import {
  IPage,
  isArticlePage,
  isArticlePageFl,
  isLiveBroadcast,
  isTVGuidePage,
} from '../types'

export const pageHasVideo = (page: IPage) => {
  if (isTVGuidePage(page)) {
    return true
  }
  if (isArticlePage(page) && page?.Content?.Item?.video) {
    return true
  }
  if (isArticlePageFl(page) && page?.Content?.Item?.video) {
    return true
  }

  return (
    page.Content.PageGrid?.find(item => isLiveBroadcast(item)) !== undefined
  )
}
