import {
  DateTime as LuxonDateTime,
  DateTimeJSOptions,
  DurationUnits,
  LocaleOptions,
} from 'luxon'
import { he } from '../locales/he'

export class DateTime {
  constructor(private _date: LuxonDateTime) {}

  static parse(
    date: string,
    format = 'yyyy-MM-dd HH:mm:ss',
    zone = 'Israel'
  ): DateTime | null {
    return date
      ? new DateTime(LuxonDateTime.fromFormat(date, format, { zone }))
      : null
  }

  toPrettyFormat() {
    const now = LuxonDateTime.local()

    if (this._date.month === now.month && this._date.year === now.year) {
      if (this._date.day === now.day) {
        return this._date.toFormat('HH:mm')
      }
      if (this._date.day === now.day - 1) {
        return `${he.yesterday}, ${this._date.toFormat('HH:mm')}`
      }
    }
    if (now.year === this._date.year) {
      return this._date.toFormat('dd.MM, HH:mm')
    }

    return this._date.toFormat('dd.MM.yyyy') //, HH:mm
  }

  toTime() {
    return this._date.toFormat('HH:mm')
  }

  static localNowToFormattedDate() {
    const now = LuxonDateTime.local({ zone: 'Israel' })

    return `${now.setLocale('he').toLocaleString({ weekday: 'long' })} ${now
      .setLocale('he')
      .toLocaleString({
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}`
  }

  isBetween(startTime: DateTime, endTime: DateTime) {
    return this._date >= startTime._date && this._date <= endTime._date
  }

  diffAbs(startTime: DateTime, unit: DurationUnits | undefined = 'minutes') {
    return Math.floor(this._date.diff(startTime._date, unit).minutes)
  }

  static local(ops: DateTimeJSOptions = { zone: 'Israel' }) {
    return new DateTime(LuxonDateTime.local(ops))
  }

  toFormat(fmt: string, opts?: LocaleOptions | undefined) {
    return this._date.toFormat(fmt, opts)
  }

  static today({ digitsOnly = false }: { digitsOnly?: boolean }) {
    return digitsOnly
      ? LuxonDateTime.local({ zone: 'Israel' }).setLocale('he').toLocaleString({
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })
      : `${LuxonDateTime.local({ zone: 'Israel' })
          .setLocale('he')
          .toLocaleString({ weekday: 'long' })} ${LuxonDateTime.local({
          zone: 'Israel',
        })
          .setLocale('he')
          .toLocaleString({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}`
  }
}
