/**
 * Created by PhpStorm.
 * User: shlomy
 * Date: 03/05/2023
 * Time: 16:29
 */
import Cookies from 'universal-cookie'

export const isUserLoggedIn = () => {
  const cookies = new Cookies()
  const rui = cookies.get('rui'),
    oui = cookies.get('oui')
  if (!(rui && oui) || rui != unscramble(oui)) {
    return false
  }
  // const rui_y = rui.substring(0, 2),
  //   rui_d = rui.substring(rui.length - 9, rui.length - 11),
  //   rui_m = rui.substring(rui.length - 2, rui.length),
  //   rui_date = new Date(Date.parse([rui_y, rui_m, rui_d].join('-')))
  // prettyLog('auth: 21', rui_date)
  return true
}

const unscramble = (str: string) => {
  const mid = Math.floor(str.length / 2)

  const firstHalf = str.slice(0, mid).split('').reverse().join('')
  const secondHalf = str.slice(mid).split('').reverse().join('')

  return secondHalf + firstHalf
}
