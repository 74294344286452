import { useEffect, useRef } from 'react'

export const PLAYER_EVENT = {
  ABORT: 'abort',
  ABR_MODE_CHANGED: 'abrmodechanged',
  ADS_COMPLETED: 'adscompleted',
  AD_AUTOPLAY_FAILED: 'adautoplayfailed',
  AD_BREAK_END: 'adbreakend',
  AD_BREAK_START: 'adbreakstart',
  AD_BUFFERING: 'adbuffering',
  AD_CAN_SKIP: 'adcanskip',
  AD_CLICKED: 'adclicked',
  AD_COMPLETED: 'adcompleted',
  AD_ERROR: 'aderror',
  AD_LOADED: 'adloaded',
  AD_MANIFEST_LOADED: 'admanifestloaded',
  AD_MIDPOINT: 'admidpoint',
  AD_MUTED: 'admuted',
  AD_PAUSED: 'adpaused',
  AD_PROGRESS: 'adprogress',
  AD_RESUMED: 'adresumed',
  AD_SKIPPED: 'adskipped',
  AD_STARTED: 'adstarted',
  AD_THIRD_QUARTILE: 'adthirdquartile',
  AD_VOLUME_CHANGED: 'advolumechanged',
  AD_WATERFALLING: 'adwaterfalling',
  AD_WATERFALLING_FAILED: 'adwaterfallingfailed',
  ALL_ADS_COMPLETED: 'alladscompleted',
  AUDIO_TRACK_CHANGED: 'audiotrackchanged',
  AUTOPLAY_FAILED: 'autoplayfailed',
  CAN_PLAY: 'canplay',
  CAN_PLAY_THROUGH: 'canplaythrough',
  CHANGE_SOURCE_ENDED: 'changesourceended',
  CHANGE_SOURCE_STARTED: 'changesourcestarted',
  CONCURRENCY_LIMIT: 'concurrencylimit',
  DRM_LICENSE_LOADED: 'drmlicenseloaded',
  DURATION_CHANGE: 'durationchange',
  EMPTIED: 'emptied',
  ENDED: 'ended',
  ENTER_FULLSCREEN: 'enterfullscreen',
  ENTER_PICTURE_IN_PICTURE: 'enterpictureinpicture',
  ERROR: 'error',
  EXIT_FULLSCREEN: 'exitfullscreen',
  FALLBACK_TO_MUTED_AUTOPLAY: 'fallbacktomutedautoplay',
  FIRST_PLAY: 'firstplay',
  FIRST_PLAYING: 'firstplaying',
  FPS_DROP: 'fpsdrop',
  FRAG_LOADED: 'fragloaded',
  IMAGE_TRACK_CHANGED: 'imagetrackchanged',
  LEAVE_PICTURE_IN_PICTURE: 'leavepictureinpicture',
  LOAD_START: 'loadstart',
  MANIFEST_LOADED: 'manifestloaded',
  MEDIA_LOADED: 'medialoaded',
  MEDIA_RECOVERED: 'mediarecovered',
  MUTE_CHANGE: 'mutechange',
  PAUSE: 'pause',
  PLAY: 'play',
  PLAYBACK_ENDED: 'playbackended',
  PLAYBACK_START: 'playbackstart',
  PLAYER_DESTROY: 'playerdestroy',
  PLAYER_RESET: 'playerreset',
  PLAYER_STATE_CHANGED: 'playerstatechanged',
  PLAYING: 'playing',
  PLAY_FAILED: 'playfailed',
  PRESENTATION_MODE_CHANGED: 'webkitpresentationmodechanged',
  PROGRESS: 'progress',
  SEEKED: 'seeked',
  SEEKING: 'seeking',
  SOURCE_SELECTED: 'sourceselected',
  STALLED: 'stalled',
  SUSPEND: 'suspend',
  TEXT_CUE_CHANGED: 'textcuechanged',
  TEXT_STYLE_CHANGED: 'textstylechanged',
  TEXT_TRACK_ADDED: 'texttrackadded',
  TEXT_TRACK_CHANGED: 'texttrackchanged',
  TIMED_METADATA: 'timedmetadata',
  TIMED_METADATA_ADDED: 'timedmetadataadded',
  TIME_UPDATE: 'timeupdate',
  GUI_RESIZE: 'playkit-ui-guiresize',

  ima: {
    VAST_TOO_MANY_REDIRECTS: 'vasttomanyredirects',
    VAST_EMPTY_RESPONSE: 'vastemptyresponse',
    VAST_ASSET_NOT_FOUND: 'vastassetnotfound',
    VAST_MALFORMED_RESPONSE: 'vastmailformedresponse',
    VAST_LOAD_TIMEOUT: 'vastloadtimeout',
    INTERNAL_ERROR: 'internalerror',
  },

  Core: {
    ABORT: 'abort',
    ABR_MODE_CHANGED: 'abrmodechanged',
    ADS_COMPLETED: 'adscompleted',
    AD_AUTOPLAY_FAILED: 'adautoplayfailed',
    AD_BREAK_END: 'adbreakend',
    AD_BREAK_START: 'adbreakstart',
    AD_BUFFERING: 'adbuffering',
    AD_CAN_SKIP: 'adcanskip',
    AD_CLICKED: 'adclicked',
    AD_COMPLETED: 'adcompleted',
    AD_ERROR: 'aderror',
    AD_FIRST_QUARTILE: 'adfirstquartile',
    AD_LOADED: 'adloaded',
    AD_MANIFEST_LOADED: 'admanifestloaded',
    AD_MIDPOINT: 'admidpoint',
    AD_MUTED: 'admuted',
    AD_PAUSED: 'adpaused',
    AD_PROGRESS: 'adprogress',
    AD_RESUMED: 'adresumed',
    AD_SKIPPED: 'adskipped',
    AD_STARTED: 'adstarted',
    AD_THIRD_QUARTILE: 'adthirdquartile',
    AD_VOLUME_CHANGED: 'advolumechanged',
    AD_WATERFALLING: 'adwaterfalling',
    AD_WATERFALLING_FAILED: 'adwaterfallingfailed',
    ALL_ADS_COMPLETED: 'alladscompleted',
    AUDIO_TRACK_CHANGED: 'audiotrackchanged',
    AUTOPLAY_FAILED: 'autoplayfailed',
    BOOKMARK_ERROR: 'bookmarkerror',
    CAN_PLAY: 'canplay',
    CAN_PLAY_THROUGH: 'canplaythrough',
    CHANGE_SOURCE_ENDED: 'changesourceended',
    CHANGE_SOURCE_STARTED: 'changesourcestarted',
    CONCURRENCY_LIMIT: 'concurrencylimit',
    DRM_LICENSE_LOADED: 'drmlicenseloaded',
    DURATION_CHANGE: 'durationchange',
    EMPTIED: 'emptied',
    ENDED: 'ended',
    ENTER_FULLSCREEN: 'enterfullscreen',
    ENTER_PICTURE_IN_PICTURE: 'enterpictureinpicture',
    ERROR: 'error',
    EXIT_FULLSCREEN: 'exitfullscreen',
    FALLBACK_TO_MUTED_AUTOPLAY: 'fallbacktomutedautoplay',
    FIRST_PLAY: 'firstplay',
    FIRST_PLAYING: 'firstplaying',
    FPS_DROP: 'fpsdrop',
    FRAG_LOADED: 'fragloaded',
    IMAGE_TRACK_CHANGED: 'imagetrackchanged',
    LEAVE_PICTURE_IN_PICTURE: 'leavepictureinpicture',
    LOADED_DATA: 'loadeddata',
    LOADED_METADATA: 'loadedmetadata',
    LOAD_START: 'loadstart',
    MANIFEST_LOADED: 'manifestloaded',
    MEDIA_LOADED: 'medialoaded',
    MEDIA_RECOVERED: 'mediarecovered',
    MUTE_CHANGE: 'mutechange',
    PAUSE: 'pause',
    PLAY: 'play',
    PLAYBACK_ENDED: 'playbackended',
    PLAYBACK_START: 'playbackstart',
    PLAYER_DESTROY: 'playerdestroy',
    PLAYER_RESET: 'playerreset',
    PLAYER_STATE_CHANGED: 'playerstatechanged',
    PLAYING: 'playing',
    PLAY_FAILED: 'playfailed',
    PRESENTATION_MODE_CHANGED: 'webkitpresentationmodechanged',
    PROGRESS: 'progress',
    RATE_CHANGE: 'ratechange',
    RESIZE: 'resize',
    SEEKED: 'seeked',
    SEEKING: 'seeking',
    SOURCE_SELECTED: 'sourceselected',
    STALLED: 'stalled',
    SUSPEND: 'suspend',
    TEXT_CUE_CHANGED: 'textcuechanged',
    TEXT_STYLE_CHANGED: 'textstylechanged',
    TEXT_TRACK_ADDED: 'texttrackadded',
    TEXT_TRACK_CHANGED: 'texttrackchanged',
    TIMED_METADATA: 'timedmetadata',
    TIMED_METADATA_ADDED: 'timedmetadataadded',
    TIME_UPDATE: 'timeupdate',
    TRACKS_CHANGED: 'trackschanged',
    USER_CLOSED_AD: 'userclosedad',
    USER_GESTURE: 'usergesture',
    VIDEO_TRACK_CHANGED: 'videotrackchanged',
    VOLUME_CHANGE: 'volumechange',
    VR_STEREO_MODE_CHANGED: 'vrstereomodechanged',
    WAITING: 'waiting',
  },
  UI: {
    GUI_RESIZE: 'playkit-ui-guiresize',
    PLAYER_RESIZE: 'playkit-ui-playerresize',
    RESIZE: 'playkit-ui-resize',
    UI_CLICKED: 'playkit-ui-uiclicked',
    UI_PRESET_CHANGE: 'playkit-ui-uipresetchange',
    UI_VISIBILITY_CHANGED: 'playkit-ui-uivisibilitychanged',
    USER_CHANGED_VOLUME: 'playkit-ui-userchangedvolume',
    USER_CLICKED_FORWARD: 'playkit-ui-userclickedforward',
    USER_CLICKED_LIVE_TAG: 'playkit-ui-userclickedlivetag',
    USER_CLICKED_MUTE: 'playkit-ui-userclickedmute',
    USER_CLICKED_PAUSE: 'playkit-ui-userclickedpause',
    USER_CLICKED_PLAY: 'playkit-ui-userclickedplay',
    USER_CLICKED_REWIND: 'playkit-ui-userclickedrewind',
    USER_CLICKED_UNMUTE: 'playkit-ui-userclickedunmute',
    USER_ENTERED_FULL_SCREEN: 'playkit-ui-userenteredfullscreen',
    USER_ENTERED_PICTURE_IN_PICTURE: 'playkit-ui-userenteredpictureinpicture',
    USER_EXITED_FULL_SCREEN: 'playkit-ui-userexitedfullscreen',
    USER_EXITED_PICTURE_IN_PICTURE: 'playkit-ui-userexitedpictureinpicture',
    USER_SEEKED: 'playkit-ui-userseeked',
    USER_SELECTED_AUDIO_TRACK: 'playkit-ui-userselectedaudiotrack',
    USER_SELECTED_CAPTIONS_STYLE: 'playkit-ui-userselectedcaptionsstyle',
    USER_SELECTED_CAPTION_TRACK: 'playkit-ui-userselectedcaptiontrack',
    USER_SELECTED_QUALITY_TRACK: 'playkit-ui-userselectedqualitytrack',
    USER_SELECTED_SPEED: 'playkit-ui-userselectedspeed',
    VIDEO_RESIZE: 'playkit-ui-videoresize',
  },
}

export function useEventListener(
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any
) {
  // Create a ref that stores handler
  const savedHandler = useRef<typeof handler>(null)
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener
    if (!isSupported) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventListener = (event: any) => savedHandler.current(event)
    element.addEventListener(eventName, eventListener)
    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}
