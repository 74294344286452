import React, { createContext } from 'react'

interface IProps {
  children: React.ReactNode
  gridId: string | number | undefined
}

export const GridBlockContext = createContext<{
  gridId: string | number | undefined
}>({
  gridId: 0,
})
export const GridBlockProvider = ({ children, gridId }: IProps) => {
  return (
    <GridBlockContext.Provider value={{ gridId: gridId }} children={children} />
  )
}
