import create from 'zustand'

interface IPlayerStore {
  kalturaReady: boolean
  setKalturaReady: (val: boolean) => void
  springStreamsReady: boolean
  setSpringStreamsReady: (val: boolean) => void
  kantarReady: boolean
  setKantarReady: (val: boolean) => void
  googlePalReady: boolean | 'error'
  setGooglePalReady: (val: boolean | 'error') => void
  playerStatePaused: boolean
  setPlayerStatePaused: (val: boolean) => void
  playerTitle: string
  setPlayerTitle: (val: string) => void
}

export const usePlayerStore = create<IPlayerStore>()(set => ({
  kalturaReady: false,
  setKalturaReady: val => set({ kalturaReady: val }),
  springStreamsReady: false,
  setSpringStreamsReady: val => set({ springStreamsReady: val }),
  kantarReady: false,
  setKantarReady: val => set({ kantarReady: val }),
  googlePalReady: false,
  setGooglePalReady: val => set({ googlePalReady: val }),
  playerStatePaused: false,
  setPlayerStatePaused: val => set({ playerStatePaused: val }),
  playerTitle: '',
  setPlayerTitle: val => set({ playerTitle: val }),
}))
