import { Duration } from 'luxon'

export const secondsFormatted = (duration: number) => {
  const seconds = Duration.fromObject({ seconds: duration })

  const displayTime =
    seconds.as('hours') >= 1
      ? seconds.toFormat('hh:mm:ss')
      : seconds.toFormat('mm:ss')

  return displayTime
}
