import { useEffect, useState } from 'react'
import { prettyLog } from '../../../utils'

const useGeoManifestFetch = () => {
  const [isIsrael, setIsIsrael] = useState<'Israel' | 'Not Israel' | false>(
    false
  )
  useEffect(() => {
    fetch(
      'https://d2lckchr9cxrss.cloudfront.net/out/v1/c73af7694cce4767888c08a7534b503c/index.m3u8'
    )
      .then(res => {
        setIsIsrael(res.status === 200 ? 'Israel' : 'Not Israel')
      })
      .catch(res => {
        prettyLog('useGeoManifestFetch: 11', res, 'error')
        setIsIsrael('Not Israel')
      })
  }, [])
  return isIsrael
}
export default useGeoManifestFetch
