import React, { createContext, Dispatch, SetStateAction, useState } from 'react'

type Props = {
  children: React.ReactNode
}

type State = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  body?: React.ReactNode
  setBody: Dispatch<SetStateAction<React.ReactNode>>
  handleClose: () => void
  feedInView: boolean
  setFeedInView: Dispatch<SetStateAction<boolean>>
}

export const OverlayContext = createContext<State>({} as State)

export const OverlayProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [body, setBody] = useState<React.ReactNode>(null)
  const [feedInView, setFeedInView] = useState<boolean>(false)

  const handleClose = () => {
    setBody(null)
    setIsOpen(false)
  }

  return (
    <OverlayContext.Provider
      value={{
        isOpen,
        setIsOpen,
        body,
        setBody,
        handleClose,
        feedInView,
        setFeedInView,
      }}
    >
      {children}
    </OverlayContext.Provider>
  )
}
