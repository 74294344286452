import { useEffect, useState } from 'react'

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msvisibilitychange'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden'
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
}

export function getIsDocumentHidden() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !(document as any)[getBrowserDocumentHiddenProp() as string]
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState<boolean>(getIsDocumentHidden())
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp() as string

    document.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  return isVisible
}
