import {
  ICategoryTreeItem,
  ILightCategoryTreeItem,
  ILightRailItem,
  ILightRailItemImage,
  IRailItem,
} from './ott'

export const railItemToLight = (item: IRailItem) => {
  const mediaFiles = []
  if (item.mediaFiles && item.mediaFiles.length > 0) {
    const { duration } = item.mediaFiles[0]
    mediaFiles.push({ duration })
  }
  return {
    id: item.id,
    description: item?.description || '',
    entryId: item.entryId || '',
    externalId: item.externalId,
    name: item.name,
    relatedEntities: item.relatedEntities,
    endDate: item.endDate,
    startDate: item.startDate,
    createDate: item.createDate,
    images: item.images.map(img => {
      return {
        imageTypeId: img.imageTypeId,
        imageTypeName: img.imageTypeName,
        ratio: img.ratio,
        url: img.url,
      } as ILightRailItemImage
    }),
    mediaFiles,
    metas: {
      SeriesID: item.metas.SeriesID?.value || '0',
      RunTime: item.metas.RunTime?.value || '0',
      EpisodeNumber: item.metas.EpisodeNumber?.value || 0,
      ShortSummary: item.metas.ShortSummary?.value || '',
      LongSummary: item.metas.LongSummary?.value || '',
      MediaType: item.metas.MediaType?.value || '',
      SeasonNumber: item.metas.SeasonNumber?.value || 0,
      geoBlockRuleId: item.metas?.geoBlockRuleId || 0,
    },
    tags: item.tags,
    type: item.type,
    typeDescription: item.typeDescription || '',
    dynamicData: item?.dynamicData || null,
  } as ILightRailItem
}
export const treeToLight = (item: ICategoryTreeItem) => {
  const { id, name, children, dynamicData, unifiedChannels } = item
  const newItem = {
    id,
    name,
    dynamicData,
    children: children.map(treeToLight),
  } as ILightCategoryTreeItem
  // eslint-disable-next-line no-prototype-builtins
  if (unifiedChannels && unifiedChannels.length > 0) {
    newItem.unifiedChannels = unifiedChannels
  }
  return newItem
}
