import axios from 'axios'
import { clientConfig } from '../../../client/clientConfig'
import { ISeriesTypeId } from '../../../types'
import { config } from '../../../lib'

const apiUrl = `${clientConfig.kaltura_end_point}asset/action/list`

export const fetchAssetByMediaId = async ({
  MEDIAID,
  ks,
}: {
  MEDIAID: string
  ks: string
}) => {
  return await axios.post(apiUrl, {
    filter: {
      objectType: 'KalturaSearchAssetFilter',
      kSql: `(and (or media_id='${MEDIAID}' epg_id='${MEDIAID}'))`,
    },
    pager: {
      objectType: 'KalturaFilterPager',
      pageIndex: 1,
      pageSize: 25,
    },
    format: 1,
    clientTag: config.kalturaClientTag,
    apiVersion: '6.1.0.28839',
    language: 'heb',
    ks: ks,
  })
}
const fetchMainRail = async (id: number | undefined, ks: string) => {
  return (
    await axios.post(apiUrl, {
      filter: { objectType: 'KalturaChannelFilter', idEqual: id, kSql: '' },
      pager: { objectType: 'KalturaFilterPager', pageIndex: 1, pageSize: 25 },
      format: 1,
      clientTag: config.kalturaClientTag,
      apiVersion: '6.1.0.28839',
      language: 'heb',
      ks: ks,
    })
  ).data.result.objects
}

const fetchPosterRegularRail = async (id: number | undefined, ks: string) => {
  return (
    await axios.post(apiUrl, {
      filter: {
        objectType: 'KalturaChannelFilter',
        idEqual: id,
        kSql: "(and (or asset_type = '1259' ) )",
      },
      pager: { objectType: 'KalturaFilterPager', pageIndex: 1, pageSize: 50 },
      format: 1,
      clientTag: config.kalturaClientTag,
      apiVersion: '6.1.0.28839',
      language: 'heb',
      ks,
    })
  ).data
}
const fetchProgramSeasons = async ({
  seriesId,
  season,
  type,
  ks,
  page,
  pagesToFetch,
}: {
  seriesId: number
  season: number | boolean
  type: string
  ks: string
  page: number
  pagesToFetch: number
}) => {
  return axios.post(apiUrl, {
    apiVersion: '6.1.0.28839',
    clientTag: config.kalturaClientTag,
    filter: {
      dynamicOrderBy: {
        name: 'EpisodeNumber',
        objectType: 'KalturaDynamicOrderBy',
        orderBy: 'META_ASC',
      },
      objectType: 'KalturaSearchAssetFilter',
      typeIn: type,
      orderBy: 'NAME_ASC',
      kSql: `(and SeriesId='${seriesId}' SeasonNumber='${season}')`,
    },
    format: 1,
    language: 'heb',
    ks: ks,
    responseProfile: {
      objectType: 'KalturaDetachedResponseProfile',
      relatedProfiles: [
        {
          objectType: 'KalturaDetachedResponseProfile',
          name: 'Episodes_In_Season',
          filter: {
            objectType: 'KalturaAggregationCountFilter',
          },
        },
      ],
    },
    pager: {
      pageSize: pagesToFetch,
      pageIndex: page,
    },
  })
}
const fetchSeasons = async (
  programId: string | number | false,
  type: string | number,
  ks: string
) => {
  return axios.post(apiUrl, {
    filter: {
      objectType: 'KalturaSearchAssetFilter',
      typeIn: type,
      dynamicOrderBy: {
        name: 'SeasonNumber',
        objectType: 'KalturaDynamicOrderBy',
        orderBy: 'META_ASC',
      },
      // orderBy: 'NAME_ASC',
      kSql: `(and SeriesID = '${programId}' asset_type = '${asset_type_enum.Episodes}')`,
      groupBy: [
        {
          objectType: 'KalturaAssetMetaOrTagGroupBy',
          value: 'SeasonNumber',
        },
      ],
    },
    pager: {
      pageSize: 50,
      pageIndex: 1,
    },
    format: 1,
    clientTag: config.kalturaClientTag,
    apiVersion: '6.1.0.28839',
    language: 'heb',
    ks: ks,
    responseProfile: {
      objectType: 'KalturaDetachedResponseProfile',
      relatedProfiles: [
        {
          objectType: 'KalturaDetachedResponseProfile',
          name: 'Episodes_In_Season',
          filter: {
            objectType: 'KalturaAggregationCountFilter',
          },
        },
      ],
    },
  })
}
export const asset_type_enum = {
  Episodes: 1268,
  Season: 1264,
  Series: 1259,
}
const fetchSimilarPrograms = async (
  seriesIdType: ISeriesTypeId | null,
  ks: string
) => {
  return axios.post(apiUrl, {
    filter: {
      objectType: 'KalturaRelatedFilter',
      idEqual: seriesIdType?.id,
      kSql: `(and asset_type='${seriesIdType?.type}')`,
      excludeWatched: false,
    },
    pager: {
      objectType: 'KalturaFilterPager',
      pageIndex: 1,
      pageSize: 25,
    },
    format: 1,
    clientTag: config.kalturaClientTag,
    apiVersion: '6.1.0.28839',
    language: 'heb',
    ks: ks,
  })
}

const FetchAssetData = async (id: string | number | false, ks: string) => {
  return axios.post(apiUrl, {
    filter: {
      objectType: 'KalturaSearchAssetFilter',
      kSql: `(and (or EntryID = '${id}' ExternalID = '${id}' media_id = '${id}' epg_id = '${id}' ) (or asset_type = '1268' asset_type = '1262' asset_type = '1259' asset_type = '1267' asset_type = '1268' asset_type = '1261' asset_type = '1260' asset_type = '1265' asset_type = '1262' asset_type = '1264' asset_type = '1259' asset_type = '1266' asset_type = 'epg' asset_type = '1265' ) )`,
    },
    pager: {
      objectType: 'KalturaFilterPager',
      pageIndex: 1,
      pageSize: 18,
    },
    format: 1,
    clientTag: config.kalturaClientTag,
    apiVersion: '6.1.0.28839',
    language: 'heb',
    ks: ks,
  })
}
const FetchProgramData = async (id: string | number, ks: string) => {
  return axios.post(apiUrl, {
    filter: {
      objectType: 'KalturaSearchAssetListFilter',
      kSql: `(and (or asset_type = 1259 ) (and SeriesID = '${id}' ) )`,
    },
    pager: {
      objectType: 'KalturaFilterPager',
      pageIndex: 1,
      pageSize: 25,
    },
    format: 1,
    clientTag: config.kalturaClientTag,
    apiVersion: '6.1.0.28839',
    language: 'heb',
    ks: ks,
  })
}

export {
  fetchMainRail,
  fetchPosterRegularRail,
  fetchProgramSeasons,
  fetchSeasons,
  fetchSimilarPrograms,
  FetchAssetData,
  FetchProgramData,
}
