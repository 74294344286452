import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Cookies from 'universal-cookie'
import { clientConfig } from '../../../client/clientConfig'
import { getUuid, prettyLog } from '../../../utils'
import axios from 'axios'
import {
  IActivePage,
  ICategoryTree,
  ICategoryTreeItem,
  Ileafs,
  IProgramData,
  IProgramPage,
  ISeriesTypeId,
} from '../../../types'
import { useRouter } from 'next/router'
import { he } from '../../../locales/he'
import { config } from '../../../lib'

type Props = {
  children: React.ReactNode
  theTree: ICategoryTreeItem[]
  leafs: Ileafs[]
  program: IProgramData | null
  // page: any
}

interface State {
  ks: string
  setKs: Dispatch<SetStateAction<string>>
  tree: ICategoryTree | null
  rails: null | Ileafs[]
  setRails: React.Dispatch<React.SetStateAction<Ileafs[]>>
  activePage: IActivePage
  setActivePage: React.Dispatch<React.SetStateAction<IActivePage>>
  hp: null | number
  allProgramsID: null | number
  searchPageID: number | null
  kalturaLoaded: { opc: boolean; kmc: boolean }
  setKalturaLoaded: React.Dispatch<
    React.SetStateAction<{ opc: boolean; kmc: boolean }>
  >
  activeGenre: number | false | string
  setActiveGenre: React.Dispatch<React.SetStateAction<number | false | string>>
  programPage: false | IProgramPage
  setProgramPage: React.Dispatch<React.SetStateAction<IProgramPage | false>>
  seriesIdType: ISeriesTypeId | null
  setSeriesIdType: React.Dispatch<React.SetStateAction<ISeriesTypeId | null>>
  programData: null | IProgramData
}

const defaultState: State = {
  ks: '',
  setKs: function (value: React.SetStateAction<string>): void {
    throw new Error('Function not implemented.' + value)
  },
  tree: null,
  rails: null,
  setRails: function (value: React.SetStateAction<Ileafs[]>): void {
    throw new Error('Function not implemented.' + value)
  },
  activePage: null,
  setActivePage: function (value: React.SetStateAction<IActivePage>): void {
    throw new Error('Function not implemented.' + value)
  },
  hp: null,
  searchPageID: 0,
  allProgramsID: null,
  kalturaLoaded: { opc: false, kmc: false },
  setKalturaLoaded: function (
    value: React.SetStateAction<{ opc: boolean; kmc: boolean }>
  ): void {
    throw new Error('Function not implemented.' + value)
  },
  activeGenre: false,
  setActiveGenre: function (
    value: React.SetStateAction<number | false | string>
  ): void {
    throw new Error('Function not implemented.' + value)
  },
  programPage: false,
  setProgramPage: function (
    value: React.SetStateAction<IProgramPage | false>
  ): void {
    throw new Error('Function not implemented.' + value)
  },
  seriesIdType: null,
  setSeriesIdType: (
    value: React.SetStateAction<ISeriesTypeId | null>
  ): void => {
    throw new Error('Function not implemented.' + value)
  },
  programData: null,
}
export const OttOverlayContext = createContext<State>(defaultState)

export const OttOverlayProvider = ({
  children,
  theTree,
  leafs,
  program,
}: Props) => {
  const [ks, setKs] = useState('')
  const [tree] = useState<ICategoryTree | null>({ list: theTree })
  const [rails, setRails] = useState(leafs)
  const [activePage, setActivePage] = useState<IActivePage>(config.activePage)
  const [searchPageID] = useState(config.searchPageId)
  const [allProgramsID] = useState(config.allProgramsID)
  const [hp] = useState<null | number>(config.hpId)
  const [kalturaLoaded, setKalturaLoaded] = useState<{
    opc: boolean
    kmc: boolean
  }>({ opc: false, kmc: false })
  const [activeGenre, setActiveGenre] = useState<number | false | string>(
    he.allGenre
  )
  const [programPage, setProgramPage] = useState<IProgramPage | false>(false)
  const [seriesIdType, setSeriesIdType] = useState<ISeriesTypeId | null>({
    id: 0,
    type: '',
  })
  const [programData, setProgramData] = useState<IProgramData | null>(program)

  useEffect(() => {
    setProgramData(program)
  }, [program])

  const { asPath } = useRouter()
  const cleanPath = useMemo(() => asPath.split('?')[0].split('#')[0], [asPath])
  // /**
  //  * init anonymous ks
  //  * */
  useEffect(() => {
    const cookies = new Cookies()
    const getKs = async () => {
      try {
        const response = await axios.post(
          clientConfig.kaltura_end_point + 'ottuser/action/anonymousLogin',
          {
            format: 1,
            partnerId: clientConfig.kaltura_partner_id,
            udid: getUuid(),
          }
        )
        const result = await response.data
        const options = {
          path: '/',
          expires: new Date(result.result.expiry * 1000),
        }
        setTimeout(getKs, result.result.expiry * 1000 - Date.now())
        cookies.set('ks_expiry', result.result.expiry, options)
        cookies.set('ks', result.result.ks, options)
        setKs(result.result.ks)
      } catch (err) {
        prettyLog('OttOverlayProvider: 33', err, 'error')
      }
    }

    const cookie_ks = cookies.get('ks')
    const cookie_ks_expiry = cookies.get('ks_expiry')
    if (cookie_ks && cookie_ks_expiry) {
      setKs(cookie_ks)
      setTimeout(getKs, cookie_ks_expiry * 1000 - Date.now())
    } else {
      getKs()
    }
  }, [])

  useEffect(() => {
    if (hp) {
      const path = cleanPath.split('/').filter(p => !!p)
      let page: IActivePage = hp
      if (path[1]) {
        switch (path[1]) {
          case 'profile':
            page = path[1]
            break
          case 'screen':
          case 'series':
            if (path[2] === 'search') {
              page = parseInt(path[3])
            } else {
              page = parseInt(path[2])
            }
            break
          default:
            if (!programPage) {
              page = 'player'
            } else {
              //TODO maybe 404
            }
        }
      }
      setActivePage(page)
    }
  }, [cleanPath, hp, programPage])
  useEffect(() => {
    setRails(leafs)
  }, [leafs])
  return (
    <OttOverlayContext.Provider
      value={{
        ks,
        setKs,
        tree,
        rails,
        setRails,
        activePage,
        setActivePage,
        hp,
        searchPageID,
        allProgramsID,
        kalturaLoaded,
        setKalturaLoaded,
        activeGenre,
        setActiveGenre,
        programPage,
        setProgramPage,
        seriesIdType,
        setSeriesIdType,
        programData,
      }}
    >
      {children}
    </OttOverlayContext.Provider>
  )
}
