import router from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePlayerStore } from '../../store/playerStore'
import { prettyLog, sendGA4 } from '../../utils'
import { usePage } from './usePage' // eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let nonceLoader: { loadNonceManager: (arg0: any) => any }
let managerPromise
const storageConsent = true
// const playbackStarted = false
// function sendAdClick() {
//   nonceManager?.sendAdClick()
// }
//
// /**
//  * Handles the user touching on the video element, passing it to PAL.
//  * @param {!TouchEvent|!MouseEvent} touchEvent
//  */
// function onVideoTouch(touchEvent) {
//   nonceManager?.sendAdTouch(touchEvent)
// }
//
// /** Informs PAL that playback has started. */
// function sendPlaybackStart() {
//   nonceManager?.sendPlaybackStart()
// }
//
// /** Informs PAL that playback has ended. */
// function sendPlaybackEnd() {
//   nonceManager?.sendPlaybackEnd()
// }
function getConsentToStorage() {
  return storageConsent
}

export const useGooglePal = () => {
  const pageData = usePage()
  const { SiteMeta } = pageData
  const googlePalTimeout = useRef<null | NodeJS.Timeout>(null)
  const [googlePalReady] = usePlayerStore(state => [state.googlePalReady])

  // const [nonce, setNonce] = useState<string | false>(false)
  const [nonceManager, setNonceManager] = useState<{ getNonce: () => string }>()

  const [pal, setPal] = useState(
    SiteMeta?.config?.player?.sendPal === 'off' ? 'off' : ''
  )

  useEffect(() => {
    // const videoElement = document.getElementById('placeholder-video');
    // videoElement.addEventListener('mousedown', (e) => void onVideoTouch(e));
    // videoElement.addEventListener('touchstart', (e) => void onVideoTouch(e));
    // videoElement.addEventListener('play', () => {
    //   if (!playbackStarted) {
    //     sendPlaybackStart();
    //     playbackStarted = true;
    //   }
    // });
    // videoElement.addEventListener('ended', () => void sendPlaybackEnd());
    // videoElement.addEventListener('error', () => {
    //   console.log("Video error: " + videoElement.error.message);
    //   sendPlaybackEnd();
    // });
    //
    // document.getElementById('generate-nonce')
    //     .addEventListener('click', generateNonce);

    // The default value for `allowStorage` is false, but can be
    // changed once the appropriate consent has been gathered.
    if (googlePalReady && window.goog) {
      const consentSettings = new window.goog.pal.ConsentSettings()
      consentSettings.allowStorage = getConsentToStorage()

      nonceLoader = new window.goog.pal.NonceLoader(consentSettings)
    }
  }, [googlePalReady])

  const setPalUndefined = useCallback(() => {
    googlePalTimeout.current && clearTimeout(googlePalTimeout.current)
    setPal('undefined')
    pageData &&
      sendGA4({
        eventName: 'pal_empty',
        pageData: pageData,
      })
  }, [pageData])

  useEffect(() => {
    const generateNonce = () => {
      if (googlePalReady && window.goog && nonceLoader) {
        const request = new window.goog.pal.NonceRequest()
        request.adWillAutoPlay = true
        request.adWillPlayMuted = true
        request.continuousPlayback = false
        request.descriptionUrl = 'https://13tv.co.il'
        request.iconsSupported = true
        request.playerType = '13tv player'
        request.playerVersion = '1.0'
        request.ppid = 'Sample PPID'
        request.sessionId = 'Sample SID'
        // Player support for VPAID 2.0, OMID 1.0, and SIMID 1.1
        request.supportedApiFrameworks = '2,7,9'
        request.url = 'https://developers.google.com/ad-manager/pal/html5'
        request.videoHeight = 480
        request.videoWidth = 640

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        managerPromise = nonceLoader.loadNonceManager(request)
        managerPromise
          .then((manager: { getNonce: () => string }) => {
            setNonceManager(manager)
            // setNonce(manager.getNonce())
          })
          .catch((error: string) => {
            prettyLog(
              'useGooglePal: 95',
              { msg: 'Error generating nonce', error },
              'error'
            )
          })
      }
    }
    generateNonce()
  }, [googlePalReady])

  useEffect(() => {
    if (SiteMeta?.config?.player?.sendPal !== 'off') {
      const onRouteChangeStart = () => {
        googlePalTimeout.current = setTimeout(setPalUndefined, 500)
        try {
          nonceManager && setPal(nonceManager.getNonce())
          googlePalTimeout.current && clearTimeout(googlePalTimeout.current)
        } catch (e) {
          setPalUndefined()
        }
      }
      nonceManager && setPal(nonceManager.getNonce())
      router.events.on('routeChangeStart', onRouteChangeStart)

      return () => {
        router.events.off('routeChangeStart', onRouteChangeStart)
      }
    }
  }, [SiteMeta?.config?.player?.sendPal, nonceManager, setPalUndefined])

  return pal
}
