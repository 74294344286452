import React, { useCallback, useState } from 'react'
import { ITimeLineElement } from '../../types'

type TimeLineContextType = {
  filterElements: ITimeLineElement[]
  setElements: (filterElements: ITimeLineElement[]) => void
}

export const TimeLineContext = React.createContext<TimeLineContextType>({
  filterElements: [],
  setElements: () => {
    return
  },
})

export const TimeLineContextProvider: React.FC = props => {
  const [filterElements, setFilterElements] = useState<ITimeLineElement[]>([])

  const setFilterElementsHandler = useCallback(
    (filterElements: ITimeLineElement[]) => {
      setFilterElements(filterElements)
    },
    []
  )

  const contextValue: TimeLineContextType = {
    filterElements: filterElements,
    setElements: setFilterElementsHandler,
  }

  return (
    <TimeLineContext.Provider value={contextValue}>
      {props.children}
    </TimeLineContext.Provider>
  )
}
