import { v4 as uuidv4 } from 'uuid'

export const getUuid = () => {
  if (window.localStorage.uuid != null) {
    return window.localStorage.uuid
  }

  const uuid = uuidv4()
  window.localStorage.uuid = uuid
  return uuid
}
