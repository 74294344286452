export enum TaboolaPageTypes {
  hp = 'hp',
  vod = 'vod',
  grid = 'grid',
  item = 'item',
  article_item_left_rail_thumbnails = 'article-item-left-rail-thumbnails',
  vod_item_left_rail_thumbnails = 'vod-item-left-rail-thumbnails',
  playlist_in_vod = 'playlist_in_vod',
  home = 'home',
  page = 'page',
  category = 'category',
  chanel26_1st = 'chanel26-1st',
  chanel26_2st = 'chanel26-2st',
  search = 'search',
  live = 'live',
  you_might_also_like = 'you-might-also-like',
  taboola_below_live_player = 'taboola-below-live-player',
  article = 'article',
  genresvod = 'genresvod',
  allprogramsvod = 'allprogramsvod',
  hpvod = 'hpvod',
  tvguide = 'tvguide',
  mivzakim = 'mivzakim',
  writer = 'writer',
  tag = 'tag',
  gridvod = 'gridvod',
}

export type TaboolaPageType =
  | TaboolaPageTypes.vod
  | TaboolaPageTypes.grid
  | TaboolaPageTypes.item
  | TaboolaPageTypes.article_item_left_rail_thumbnails
  | TaboolaPageTypes.vod_item_left_rail_thumbnails
  | TaboolaPageTypes.playlist_in_vod
  | TaboolaPageTypes.home
  | TaboolaPageTypes.page
  | TaboolaPageTypes.category
  | TaboolaPageTypes.chanel26_1st
  | TaboolaPageTypes.chanel26_2st
  | TaboolaPageTypes.search
  | TaboolaPageTypes.you_might_also_like
  | TaboolaPageTypes.taboola_below_live_player
  | TaboolaPageTypes.live
  | TaboolaPageTypes.writer
  | TaboolaPageTypes.tag
  | TaboolaPageTypes.gridvod
  | TaboolaPageTypes.genresvod
  | TaboolaPageTypes.allprogramsvod
  | TaboolaPageTypes.hpvod
  | TaboolaPageTypes.tvguide
  | TaboolaPageTypes.mivzakim
  | TaboolaPageTypes.hp
  | TaboolaPageTypes.article

export const TaboolaList = {
  YOU_MIGHT_ALSO_LIKE_FOR_ITEM: 'you-might-also-like-for-item',
  DESKTOP_TIMELINE_BOX: 'desktop-timeline-box',
  MOBILE_TIMELINE_BOX: 'mobile-timeline-box',
} as const

export enum TaboolaSuffixId {
  pageFooter = 'page-footer',
  articleFooter = 'article-footer',
  youMightAlsoLike = 'you-might-also-like',
}

export interface ITaboolaRoot {
  ready: boolean
  pubName: string
  os: string
}
