import React, { createContext, useState } from 'react'

interface IHeroContext {
  imageHeight: number
  setImageHeight: React.Dispatch<React.SetStateAction<number>>
}

const defaultState = {
  imageHeight: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImageHeight: () => {},
} as IHeroContext

export const HeroContext = createContext(defaultState)

type Props = {
  children: React.ReactNode
}

export const HeroProvider = ({ children }: Props) => {
  const [imageHeight, setImageHeight] = useState(0)
  return (
    <HeroContext.Provider value={{ imageHeight, setImageHeight }}>
      {children}
    </HeroContext.Provider>
  )
}
