import { hostClass } from '../styles/GlobalVariables'

interface NativeAppInfo {
  appName: string
  appVersion: string
  platform: string
  osVersion: string
  rest: string
}
declare global {
  interface Window {
    _nativeAppInfo?: NativeAppInfo | null
  }
}

export function isOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
function getNativeAppInfo() {
  if (window._nativeAppInfo !== undefined) {
    return window._nativeAppInfo
  }
  const userAgent = window.navigator.userAgent
  if (userAgent.includes('reshet_android')) {
    return { appName: 'Reshet', platform: 'Android' }
  }
  if (userAgent.includes('reshet_ios')) {
    return { appName: 'Reshet', platform: 'iOS' }
  }
  const x = /^([^/]+)\/([^/]+) ([^/]+)\/([^/]+) (.*)$/.exec(userAgent)
  if (!x) {
    return null
  }

  const [, appName, appVersion, platform, osVersion, rest] = x
  const info = { appName, appVersion, platform, osVersion, rest }
  window._nativeAppInfo = info
  return info
}

export function isNativeApp() {
  return (
    getNativeAppInfo()?.appName === 'Reshet' ||
    window.location.search.includes('nativeApp')
  )
}

export function isAndroidApp() {
  return getNativeAppInfo()?.platform === 'Android'
}

export function isIosApp() {
  return getNativeAppInfo()?.platform === 'iOS'
}

export function isBrowser() {
  return !isNativeApp()
}

/** Add css on body element for nativeApp or browser */
export function addHostCss() {
  document.body.classList.add(
    isNativeApp() ? hostClass.nativeApp : hostClass.browser
  )
}

// console.log(getNativeAppInfo("Reshet/1.2.3_rc1 Android/11.0 Samsung/Galaxy_S10"))
// console.log(getNativeAppInfo("Reshet/12.3.10 iOS/15.0 Apple/iPhone13"))
