import { playerConfig } from './constants'
import { utils } from './utils'

const TRACKING_ID =
  utils.getEnv() === 'prod'
    ? playerConfig.gtag_id_prod
    : playerConfig.gtag_id_testing
export const GA_TRACKING_ID = TRACKING_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  })
}

type GTagEvent = {
  name: EventsName | string
  category: string
  label: string
  url?: string
  googleOnly?: boolean
  unique_args?: {
    Page_url?: string
    clean_page_url?: string
    ReferenceId?: string
    location?: string
    Error_code_kal?: string
  }
}

export type EventsName =
  | 'playback_start_live'
  | 'video_start_live'
  | 'playback_start'
  | 'video_start'
  | 'plugin_not_load'
  | 'AdBlock_Page_Load'
  | 'country_restricted'

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const fireEvents = ({
  name,
  category = 'general',
  label = '',
  url,
}: GTagEvent) => {
  window.gtag('event', name, {
    event_category: category,
    event_label: label,
    page_path: url,
  })
}
