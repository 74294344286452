import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { IAdmin_Cookie } from '../../types'

export function useAdminCookie() {
  const [admin, setAdmin] = useState<IAdmin_Cookie | false>(false)

  useEffect(() => {
    const cookies = new Cookies()
    const admin_cookie = cookies.get('admin_cookie') as IAdmin_Cookie
    setAdmin(admin_cookie)
  }, [])

  return admin
}
