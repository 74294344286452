import React, { createContext, useState } from 'react'

interface IHeaderContext {
  headerHeight: number
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>
}

const defaultState = {
  headerHeight: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setHeaderHeight: () => {},
} as IHeaderContext

export const HeaderContext = createContext(defaultState)

type Props = {
  children: React.ReactNode
}

export const HeaderProvider = ({ children }: Props) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  return (
    <HeaderContext.Provider value={{ headerHeight, setHeaderHeight }}>
      {children}
    </HeaderContext.Provider>
  )
}
