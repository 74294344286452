import parse from 'html-react-parser'
import { IItem } from '../types'
import { cleanHtmlString } from '../components/ContentParser/utils'

export type ArticleImage = {
  src?: string
  caption?: string
}

export const getArticleImagesAndCaptions = (article: IItem) => {
  // gets <figure> tags and their content
  const figures = cleanHtmlString(article?.postContent || '').match(
    /(?=<figure>)(.*?)(?=<\/figure>)/gms
  )
  const imageMain = {
    src: article?.image?.src,
    caption: article?.image?.description,
  } as ArticleImage

  if (!figures) {
    if (!imageMain) {
      return
    }
    return [imageMain]
  }

  const imagesAndCaptions = figures.map(figure => {
    const img = figure.match(/(?=<img)(.*?)(?=>)/gms)?.[0].concat('/>') || ''
    const figcaption =
      figure
        .match(/(?=<figcaption>)(.*?)(?=<\/figcaption>)/gms)?.[0]
        .concat('</figcaption>') || ''
    console.log({ img, figcaption })
    const parsedImg = parse(img)
    const parsedFigcaption = parse(figcaption)

    if (typeof parsedImg === 'string') {
      return { src: undefined, caption: undefined }
    }

    return {
      src: (parsedImg as JSX.Element).props.src as string,
      caption: (parsedFigcaption as JSX.Element).props.children as string,
    }
  }) as ArticleImage[]

  return imageMain ? [imageMain, ...imagesAndCaptions] : imagesAndCaptions
}
