import axios from 'axios'
import { clientConfig } from '../client/clientConfig'
import {
  asset_type_enum,
  FetchProgramData,
  fetchProgramSeasons,
  fetchSeasons,
} from './Components/API/API'
import { config } from '../lib'
import { prettyLog } from '../utils'
import {
  IActivePage,
  ILightRailItem,
  IRailItem,
  IServeByDevice,
  railItemToLight,
  treeToLight,
} from '../types'
import { he } from '../locales/he'
import { IOttSeason } from './types'

export const ottRoot = '/allshows'
const apiUrl = `${clientConfig.kaltura_end_point}asset/action/list`
export const fetchRailData = async ({
  railId,
  ks,
  kaltura_end_point,
  pageSize = 25,
  itemsLimit = 25,
  kSql = '',
}: {
  railId: number | undefined | string
  ks: string
  kaltura_end_point: string
  pageSize?: number
  itemsLimit?: number
  kSql?: string
}) => {
  if (ks && railId && kaltura_end_point) {
    let items = [] as IRailItem[]
    try {
      let response,
        pageIndex = 0
      do {
        pageIndex++
        response = await axios.post(kaltura_end_point + 'asset/action/list', {
          filter: {
            objectType: 'KalturaChannelFilter',
            idEqual: railId,
            kSql: kSql,
          },
          pager: {
            objectType: 'KalturaFilterPager',
            pageIndex: pageIndex,
            pageSize: pageSize,
          },
          format: 1,
          clientTag: config.kalturaClientTag,
          apiVersion: '6.1.0.28839',
          language: 'heb',
          ks: ks,
        })
        items = items.concat(response.data.result.objects as IRailItem[])
      } while (
        pageIndex * pageSize <
        Math.min(response.data.result.totalCount, itemsLimit)
      )
      const lightItems = items.filter(i => !!i.id).map(railItemToLight)
      return {
        response: lightItems,
        totalCount: response.data.result.totalCount,
      }
    } catch (err) {
      prettyLog('Api: 22', { fetchRailData: err })
    }
  }
  return { response: null, totalCount: 0 }
}

export type ISearchParams = {
  typeValue: number
  searchQuery: string
  pageSize: number
  pageIndex?: number
  ks: string
}

export const SearchApi = async ({
  pageIndex = 1,
  typeValue,
  searchQuery,
  pageSize,
  ks,
}: ISearchParams) => {
  try {
    let ksql: string
    switch (typeValue) {
      case asset_type_enum.Series:
      case asset_type_enum.Episodes:
        ksql = `(or (and (or asset_type='${typeValue}') (or name*'${searchQuery} 'name~'${searchQuery} 'Keywords*'${searchQuery} 'Genre*'${searchQuery} 'SubGenre*'${searchQuery}')))`
        break
      default:
        ksql = `(or (and (or asset_type='${typeValue}') (or name*'${searchQuery} 'name~'${searchQuery} 'director*'${searchQuery} 'Keywords*'${searchQuery} 'Actors*'${searchQuery} 'Genre*'${searchQuery} 'SubGenre*'${searchQuery}')) (and asset_type='epg' start_date<'0' (or name*'${searchQuery} 'name~'${searchQuery} 'director*'${searchQuery} 'Keywords*'${searchQuery} 'Genre*'${searchQuery} 'SubGenre*'${searchQuery} 'Actors*'${searchQuery}')))`
    }
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        apiVersion: '6.3.0',
        clientTag: config.kalturaClientTag,
        format: 1,
        ks: ks,
        filter: {
          kSql: ksql,
          name: searchQuery,
          objectType: 'KalturaSearchAssetFilter',
          orderBy: 'RELEVANCY_DESC',
          typeIn: typeValue,
        },
        pager: {
          objectType: 'KalturaFilterPager',
          pageSize: pageSize,
          pageIndex: pageIndex,
        },
      }),
    })

    if (!response.ok) {
      console.error(`Failed to fetch. Status: ${response.status}`)
      return
    }
    const data = await response.json()
    if (data?.result?.objects) {
      return data
    }
  } catch (error) {
    console.error('Error making API call:', error)
  }
  return null
}

export const ServeByDevice = async ({
  end_point,
  partner_id,
  application_name,
  udid,
}: {
  end_point: string
  partner_id: string
  application_name: string
  udid: string
}) => {
  try {
    const response = await axios.post(
      `${end_point}configurations/action/serveByDevice`,
      {
        apiVersion: '6.3.0',
        applicationName: application_name,
        clientVersion: '2.0.0',
        platform: 'Other',
        udid: udid,
        tag: '',
        partnerId: partner_id,
      }
    )
    return response.data as IServeByDevice
  } catch (err) {
    prettyLog('OttOverlayProvider: 61', err, 'error')
  }
  return {} as IServeByDevice
}
export const AnonymousLogin = async ({
  kaltura_end_point,
  kaltura_partner_id,
  udid,
}: {
  kaltura_end_point: string
  kaltura_partner_id: string
  udid: string
}) => {
  const ks = '',
    expiry = 0
  try {
    const response = await axios.post(
      kaltura_end_point + 'ottuser/action/anonymousLogin',
      {
        format: 1,
        partnerId: kaltura_partner_id,
        udid: udid,
      }
    )
    return await response.data.result
  } catch (err) {
    prettyLog('[[...all]]: 167', err, 'error')
  }
  return { ks, expiry }
}
export const CategoryTree = async ({
  kaltura_end_point,
  ks,
  root,
}: {
  kaltura_end_point: string
  ks: string
  root: string
}) => {
  const response = await axios.post(
    kaltura_end_point + 'categorytree/action/get',
    {
      apiVersion: '6.3.0',
      categoryItemId: root,
      filter: true,
      ks: ks,
    }
  )

  const data = await response.data.result
  return data.children.map(treeToLight)
}
export const getActivePage = ({
  hpId,
  path,
}: {
  hpId: string | number
  path: string[]
}) => {
  let page: IActivePage = hpId
  if (path[0]) {
    switch (path[0]) {
      case 'profile':
        page = path[0]
        break
      case 'screen':
      case 'series':
        if (path[1] === 'search') {
          page = parseInt(path[2])
        } else {
          page = parseInt(path[1])
        }
        break
      default:
        page = 'player'
    }
  }
  return page
}
export const getDataForProgram = async ({
  path,
  ks,
}: {
  path: string[]
  ks: string
}) => {
  let seriesId,
    totalCount = 0,
    season,
    episode,
    programData,
    response,
    seasonsList,
    episodes = [] as ILightRailItem[]
  seriesId = season = episode = programData = seasonsList = null
  try {
    if (path[0] === 'series') {
      seriesId = parseInt(path[1])
      response = await FetchProgramData(seriesId, ks)
      if (response.data.result.totalCount > 0) {
        const { description, externalId, images, metas, name, tags } =
          response.data.result.objects[0]
        programData = { description, externalId, images, metas, name, tags }

        response = await fetchSeasons(seriesId, asset_type_enum.Episodes, ks)
        if (response.data.result.objects) {
          seasonsList = response.data.result.objects.map(
            (obj: {
              type: number
              metas: { SeasonNumber: { value: number } }
              tags: { SeasonName?: { objects: { value: string }[] } }
            }) => {
              const meta = obj.metas
              return {
                type: obj.type,
                name: he.season + ' ' + meta.SeasonNumber.value,
                position: meta.SeasonNumber.value,
                seasonName:
                  obj.tags.SeasonName?.objects[0].value ||
                  'עונה ' + meta.SeasonNumber.value,
              } as IOttSeason
            }
          )
        }

        season = seasonsList[0].position
        const type = seasonsList[0].type
        if (path.length >= 4 && path[2] === 'season') {
          season = parseInt(path[3])
        }

        const pagesToFetch = 50
        let page = 1
        totalCount = 0
        do {
          response = await fetchProgramSeasons({
            seriesId,
            season,
            type,
            ks,
            page,
            pagesToFetch,
          })
          if (response.data.result.objects) {
            page++
            totalCount = response.data.result.totalCount || 0
            episodes = episodes.concat(
              response.data.result.objects.map(railItemToLight)
            )
          }
        } while ((page - 1) * pagesToFetch < Math.min(totalCount, 50))

        if (path.length > 4) {
          episode = path[4]
        } else {
          episode = episodes[0].id
        }
      }
    }
  } catch (e) {
    prettyLog('Api: 251', {
      msg: 'failed fetch program data',
      e,
      seriesId,
      season,
      episode,
      programData,
    })
  }
  return {
    seriesId,
    programData,
    season,
    episode,
    episodes,
    seasonsList,
    totalCount,
  }
}
