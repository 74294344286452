import { useEffect, useState } from 'react'
import app from '../../../public/static/ad_units_slot_app.json'
import desktop from '../../../public/static/ad_units_slot_desktop.json'
import mobile from '../../../public/static/ad_units_slot_mobile.json'
import { getAdDeviceType, isDesktop } from '../../client'

// TODO: CRITICAL ISSUE
export const adDeviceType = '' as string

function initGoogleTags() {
  window.googletag = window.googletag || { cmd: [] }

  // Only initialize pubads services once
  if (window.googletag.pubadsReady) {
    return
  }

  window.googletag.cmd.push(() => {
    /**
     *  Event listeners can be implemented as per reference
     *  https://developers.google.com/publisher-tag/samples/ad-event-listeners
     */

    //googletag.pubads().addEventListener('slotOnload', event => {})
    googletag.pubads().addEventListener('slotRenderEnded', event => {
      const slotId = event.slot.getSlotElementId()
      const callback = renderCallbacks.find(callback => callback.id === slotId)
      callback && callback.callback(event)
    })

    window.googletag.enableServices()
  })
}

const renderCallbacks: {
  id: AdId
  callback: (event: googletag.events.SlotRenderEndedEvent) => void
}[] = []

type Props = {
  id?: AdId
  mobileAdId?: AdId
  desktopAdId?: AdId
  renderCallback?: (event: googletag.events.SlotRenderEndedEvent) => void
}

export type MobileAdId = keyof MobileAdUnitSlots
export type DesktopAdId = keyof DesktopAdUnitSlots
export type AppAdId = keyof AppAdUnitSlots

export type AdId = MobileAdId | DesktopAdId | AppAdId

export const useAdConfigs = ({
  id,
  mobileAdId,
  desktopAdId,
  renderCallback,
}: Props): IAdConfig | null => {
  const [adConfig, setAdConfig] = useState<IAdConfig | null>(null)

  useEffect(() => {
    const finalAdId = id || (isDesktop() ? desktopAdId : mobileAdId)
    if (!finalAdId) {
      console.warn('no ad id defined', { id, desktopAdId, mobileAdId })
      return
    }

    if (renderCallback) {
      renderCallbacks.push({ id: finalAdId, callback: renderCallback })
    }

    initGoogleTags()
    const adDeviceType = getAdDeviceType()

    let config
    switch (adDeviceType) {
      case 'desktop':
        config = desktop[finalAdId as DesktopAdId] as IAdConfig
        break
      case 'mobile':
        config = mobile[finalAdId as MobileAdId] as IAdConfig
        break
      case 'app':
        config = app[finalAdId as AppAdId] as IAdConfig
        break
    }

    if (config) {
      // currently not in use
      // console.warn('ad config not found for id', finalAdId)
      config.domId = finalAdId
      setAdConfig(config)
    }

    return () => {
      const index = renderCallbacks.findIndex(cb => cb.id === finalAdId)
      renderCallbacks.splice(index, 1)
    }
  }, [desktopAdId, id, mobileAdId, renderCallback])

  return adConfig
}

export type AdDeviceType = keyof AdConfigs

export type AdConfigs = {
  desktop: DesktopAdUnitSlots
  mobile: MobileAdUnitSlots
  app: AppAdUnitSlots
}

type DesktopAdUnitSlots = {
  Top: IAdConfig
  Inboard: IAdConfig
  Poster: IAdConfig
  Box_7_1: IAdConfig
  Box_7_2: IAdConfig
  Box_7_3: IAdConfig
  Box_3_1: IAdConfig
  Box_3_2: IAdConfig
  Box_3_3: IAdConfig
  Strip1: IAdConfig
  Strip2: IAdConfig
  Strip3: IAdConfig
  FooterStrip: IAdConfig
  In_article_box_1: IAdConfig
  In_article_box_2: IAdConfig
  In_article_box_3: IAdConfig
  In_article_box_4: IAdConfig
  Maavaron_Desktop: IAdConfig
  Maavaron_Desktop_T: IAdConfig
  Inread: IAdConfig
  Ozen_right: IAdConfig
  Ozen_left: IAdConfig
  VodPlaylist_box_1: IAdConfig
  VodPlaylist_box_2: IAdConfig
  VodPlaylist_box_3: IAdConfig
}

type MobileAdUnitSlots = {
  Maavaron_main: IAdConfig
  Maavaron_ros: IAdConfig
  Maavaron_ros_T: IAdConfig
  Top_mobile: IAdConfig
  Mid_box_1: IAdConfig
  Mid_box_2: IAdConfig
  Mid_box_3: IAdConfig
  Mid_box_4: IAdConfig
  Footerstrip: IAdConfig
  In_article_box_1: IAdConfig
  In_article_box_2: IAdConfig
  In_article_box_3: IAdConfig
  In_article_box_4: IAdConfig
  In_article_box_5: IAdConfig
  VodPlaylist_box_1: IAdConfig
  VodPlaylist_box_2: IAdConfig
  VodPlaylist_box_3: IAdConfig
}

type AppAdUnitSlots = {
  Maavaron_main: IAdConfig
  Top_mobile: IAdConfig
  Mid_box_1: IAdConfig
  Mid_box_2: IAdConfig
  Mid_box_3: IAdConfig
  Mid_box_4: IAdConfig
  In_article_box_1: IAdConfig
  In_article_box_2: IAdConfig
  In_article_box_3: IAdConfig
  In_article_box_4: IAdConfig
  In_article_box_5: IAdConfig
  VodPlaylist_box_1: IAdConfig
  VodPlaylist_box_2: IAdConfig
  VodPlaylist_box_3: IAdConfig
  Main_Footer_web: IAdConfig
  Ros_Footer_web: IAdConfig
}

type IAdConfig = {
  id: string
  name: string
  size: number[][] | number[] | string[]
  domId?: string
}
