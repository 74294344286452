import { utils } from '../components/Player/Helpers/utils'

export interface ILog {
  title: string
  toBeLogged: unknown
  type?: null | 'error' | 'warning'
  showDate?: boolean
  color?: { text: string; bg: string }
}

const log = ({ showDate, toBeLogged, type, title, color }: ILog) => {
  let c = `background-color:${color?.bg || 'black'};color:${
    color?.text || 'white'
  };`
  if (type === 'error') {
    c = 'background-color:darkred;color:white;'
  }
  if (type === 'warning') {
    c = 'background-color:yellow;color:black;'
  }
  if (showDate) {
    const d = new Date()
    console.log(
      `%c${title}`,
      `${c}padding:7px 10px;font-weight:700;font-size:1.1`,
      [d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds()].join(
        ':'
      ),
      toBeLogged
    )
  } else {
    console.log(
      `%c${title}`,
      `${c}padding:7px 10px;font-weight:700;font-size:1.1`,
      toBeLogged
    )
  }
}
export const prettyLog = (
  title: string,
  toBeLogged: unknown,
  type?: null | 'error' | 'warning'
) => {
  log({ title, toBeLogged, type, showDate: false })
}

export const taboolaLog = (toBeLogged: unknown, type?: 'error') => {
  const title = 'taboola_log'
  const show =
    utils.getParameterByName(title) === 'true' ||
    sessionStorage.getItem(title) === 'true'
  sessionStorage.setItem(title, String(show))
  if (show) {
    const color = type === 'error' ? 'red' : 'white'
    log({
      title,
      toBeLogged,
      type,
      showDate: true,
      color: { bg: '#054164', text: color },
    })
  }
}

export const ottLog = (toBeLogged: unknown, type?: 'error') => {
  const title = 'ott_log'
  const shoe_ott_log =
    utils.getParameterByName(title) === 'true' ||
    sessionStorage.getItem(title) === 'true'
  sessionStorage.setItem(title, String(shoe_ott_log))
  if (shoe_ott_log) {
    const color = type === 'error' ? 'red' : 'white'
    log({
      title,
      toBeLogged,
      type,
      showDate: true,
      color: { bg: '#056447', text: color },
    })
  }
}
