import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { clientConfig } from '../client/clientConfig'
import { defineNativeToWeb } from '../client/nativeToWeb'
import { useNativeAppRouter } from '../contexts'
import { IPageProps } from '../types'
import { config } from '../lib'

function MyApp({ Component, pageProps }: AppProps) {
  const allProps = pageProps as Partial<IPageProps> | undefined

  const page = allProps?.page
  const adTargetEnv = allProps?.adTargetEnv || config.adTargetEnv

  useNativeAppRouter(page, adTargetEnv || '')

  defineNativeToWeb()
  return <Component {...pageProps} />
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (clientConfig.disableMetrics === '1') {
    return
  }

  console.log(metric)
}

export default MyApp
