import { IPlaylistEpisode, ISeason, IVodPlaylist } from '../types'

export type Season = {
  id: string
  name: string
  episodes: Episode[]
}

export interface Episode extends IPlaylistEpisode {
  seasonId: string
}

export const displayShowMore = (
  episodes: IPlaylistEpisode[],
  episodesGroupCounter: number
) => {
  return episodes.length > episodesGroupCounter * 15 + 7
}

export const getSeasons = (seasons: { [key: string]: ISeason }) => {
  const seasonsWithIds = [...Object.entries(seasons)].map(([key, season]) => ({
    ...season,
    id: key,
  }))

  const newSeasons: Season[] = seasonsWithIds
    .filter(season => {
      return !!season.episodes
    })
    .map(season => ({
      ...season,
      episodes: [...Object.values(season.episodes)].map(
        episode => ({ ...episode, seasonId: season.id } as Episode)
      ),
    }))

  // NOTE: the function will for some reason return the unsorted array
  // invested hours counter: 3
  // last modified: 04.10.22
  return [...newSeasons].sort(({ id: aId }, { id: bId }) => {
    if (aId < bId) {
      return 1
    }
    if (aId > bId) {
      return -1
    }
    return 0
  })
}

export const getSeason = (seasons: Season[], episode: Episode) => {
  return seasons.find(season => season.id === episode.seasonId) || seasons[0]
}

export const getCurrentEpisode = (
  seasons: Season[],
  path: string,
  episode?: IPlaylistEpisode | null
) => {
  if (!episode) {
    const newPath = path.split('/').filter(str => !!str.length)
    const seasonString = newPath.filter(s => s.includes('season'))[0]
    const seasonStringIndex = newPath.indexOf(seasonString)
    const episodeString = newPath[seasonStringIndex + 1]

    for (const season of seasons) {
      for (const episodeItem of season.episodes) {
        if (episodeItem.link.includes(episodeString)) {
          return episodeItem
        }
      }
    }

    seasons.find(({ episodes }) =>
      episodes?.find(({ link }) => link.includes(episodeString))
    )

    return seasons[0]?.episodes[0]
  }

  const seasonId = seasons.find(season =>
    season.episodes.find(ep => ep.id === episode.id)
  )?.id

  const newEpisode: Episode = {
    ...episode,
    seasonId: seasonId || seasons[0].id,
  }

  return newEpisode
}

export const getNextEpisode = (seasons: Season[], episode: Episode) => {
  const currentSeason = getSeason(seasons, episode)
  const indexOfEpisode = currentSeason.episodes
    .map(e => e.id)
    .indexOf(episode.id)

  if (indexOfEpisode <= 0) {
    // If last season in list
    if (seasons.indexOf(currentSeason) === seasons.length - 1) {
      // Return first episode in list
      return currentSeason.episodes[0]
    }
    // Otherwise return next season episode
    const nextSeason = seasons[seasons.indexOf(currentSeason) - 1]
    return nextSeason?.episodes[nextSeason.episodes.length - 1] ?? null
  }

  return currentSeason.episodes[indexOfEpisode - 1]
}

export const getShowData = (vodPlaylist: IVodPlaylist, path: string) => {
  const { episodesSeasonsMap, currentEpisode: currentEp } = vodPlaylist
  const seasons = episodesSeasonsMap ? getSeasons(episodesSeasonsMap) : []
  const episode = path.includes('episode-') ? null : currentEp
  const currentEpisode = getCurrentEpisode(seasons, path, episode)

  return {
    seasons,
    currentEpisode,
  }
}
