import { sleep } from '../utils'
import { webToNative } from './webToNative'

declare global {
  interface Window {
    nativeToWeb?: typeof nativeToWeb
  }
}
export const nativeToWeb = {
  async loopback(payload: unknown, id: string, delayMillis: number) {
    await sleep(delayMillis)
    webToNative.postMessage({
      action: 'onLoopbackResponse',
      args: { payload, id },
    })
  },
}

export function defineNativeToWeb() {
  if (typeof window === 'undefined') {
    return
  }
  if (window.nativeToWeb) {
    return
  }
  window.nativeToWeb = nativeToWeb
}
