import { DateTime } from '.'
import { IShow } from '../types'

export const getShowProgress = (
  currentShow: IShow,
  nextShow?: IShow
): number => {
  const nextShowDateTime = nextShow
    ? `${nextShow.show_date} ${nextShow.start_time}`
    : `${currentShow.show_date} 06:00`

  const showStartTime = DateTime.parse(
    `${currentShow.show_date} ${currentShow.start_time}`,
    'yyyy-MM-dd HH:mm'
  )
  const showEndTime = DateTime.parse(nextShowDateTime, 'yyyy-MM-dd HH:mm')

  if (!showStartTime || !showEndTime) {
    return 0
  }

  const currentTime = DateTime.local()

  if (!currentTime.isBetween(showStartTime, showEndTime)) {
    return 0
  }

  const intervalSinceShowStart = currentTime.diffAbs(showStartTime)
  const showDuration = showEndTime.diffAbs(showStartTime)

  return (intervalSinceShowStart / showDuration) * 100
}
