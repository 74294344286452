import { createContext } from 'react'
import { theme as globalTheme } from '../../styles/GlobalVariables'
import {
  IPage,
  isGridVodPage,
  isLiveVodPage,
  isTVGuidePage,
  isVodPage,
} from '../../types'

export type Theme = 'portal' | 'vod' | 'vod-main'

export const ThemeContext = createContext<Theme>('portal')

type Props = {
  children: React.ReactNode
  page: IPage
}

const getTheme = (page: IPage): Theme => {
  if (isTVGuidePage(page) || isLiveVodPage(page)) {
    return globalTheme.vod
  }

  if (isVodPage(page) || isGridVodPage(page)) {
    return globalTheme.vodMain
  }

  return globalTheme.portal
}

export const ThemeProvider = ({ children, page }: Props) => {
  const theme = getTheme(page)

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}
