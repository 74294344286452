import { playerConfig } from './constants'
export let intervalTimerId: number

export class IntervalTimer {
  callback: () => void
  interval: number
  count: number
  timerId: number
  startTime: Date
  state: number
  remaining: number

  constructor(callback: () => void, interval: number, count: number) {
    this.callback = callback
    this.interval = interval
    this.count = count
    this.remaining = 0
    this.state = 1 //  0 = idle, 1 = running, 2 = paused, 3= resumed
    this.startTime = new Date()
    this.timerId = window.setInterval(
      this.endOfInterval,
      this.interval
    ) as number
    intervalTimerId = this.timerId
  }

  endOfInterval = () => {
    this.callback()
    this.startTime = new Date()
  }

  pause = () => {
    if (this.state !== 1) {
      return
    }
    if (this.startTime) {
      this.remaining =
        this.interval - (new Date().getTime() - this.startTime.getTime())
    }
    this.clear()
    this.state = 2
  }

  resume = () => {
    if (this.state !== 2) {
      return
    }
    this.state = 3
    window.setTimeout(this.timeoutCallback, this.remaining)
  }

  clear = () => {
    clearInterval(this.timerId)
  }

  timeoutCallback = () => {
    if (this.state !== 3) {
      return
    }
    this.endOfInterval()
    this.timerId = window.setInterval(this.endOfInterval, this.interval)
    this.state = 1
    intervalTimerId = this.timerId
  }
}

export class Timer {
  timerId: number
  count: number
  start: Date
  remaining: number
  refreshTimer: number
  callback: () => void
  intervalTimer: IntervalTimer

  constructor(callback: () => void, delay: number) {
    this.callback = callback
    this.refreshTimer = playerConfig.DFPParams.refresh_timer * 60
    this.remaining = delay
    this.count = 0
    this.start = new Date()
    console.log('Create Timer onject')

    this.intervalTimer = new IntervalTimer(
      () => {
        this.count = this.count + 20
        //reload page after 1 hour
        if (this.count > this.refreshTimer) {
          location.reload()
        }

        if (
          intervalTimerId &&
          this.count > playerConfig.DFPParams.timer_BB * 60 - 20
        ) {
          clearInterval(intervalTimerId)
        }

        console.log(
          '%cKaltura DFP',
          'background-color:black;color:white;padding:2px 5px;',
          this.count,
          'sec playing '
        )
      },
      20000,
      this.count
    )

    this.timerId = 0
    this.intervalTimer.resume()
  }

  pause() {
    this.intervalTimer.pause()
    window.clearTimeout(this.timerId)
    this.remaining -= Date.now() - this.start.getTime()
  }

  resume() {
    this.intervalTimer.resume()
    this.start = new Date(new Date())
    window.clearTimeout(this.timerId)
    this.timerId = window.setTimeout(this.callback, this.remaining)
  }

  clear() {
    window.clearTimeout(this.timerId)
  }

  clearInterval() {
    clearInterval(intervalTimerId)
  }
}
