import { DateTime } from 'luxon'
import { IBroadcastWeek, IShow } from '../types'

export const mapBroadcastWeek = (
  broadcastWeek: IBroadcastWeek[]
): IBroadcastWeek[] => {
  const localTime = DateTime.local({ zone: 'Israel' })
  const newBroadcastWeek =
    localTime.hour >= 6
      ? broadcastWeek.slice(1, broadcastWeek.length)
      : broadcastWeek

  return newBroadcastWeek.map(day => {
    const mappedShows = day.shows
      .map(show => {
        if (!show.start_time || !show.show_date) {
          return
        }

        let newShowDate
        const start_time = DateTime.fromFormat(show.start_time, 'HH:mm', {
          zone: 'Israel',
        })
        const midnight = localTime.startOf('day')
        const six = localTime
          .startOf('day')
          .plus({ hours: 6 })
          .minus({ minutes: 1 })

        if (!start_time || !midnight || !six) {
          return
        }

        if (start_time >= midnight && start_time < six) {
          newShowDate = DateTime.fromFormat(show.show_date, 'yyyy-MM-dd', {
            zone: 'Israel',
          })
            .plus({ days: 1 })
            .toFormat('yyyy-MM-dd')
        }

        return { ...show, show_date: newShowDate || show.show_date } as
          | IShow
          | undefined
      })
      .filter(show => !!show)

    return { ...day, shows: mappedShows } as IBroadcastWeek
  })
}
