import zipWith from 'lodash/zipWith'
import { IMatrixElement, IPost } from '../types'

export const parseMatrixLayout = ({
  matrixElements,
  is_split_categories,
  layout,
  layoutType,
}: {
  matrixElements: IMatrixElement[]
  is_split_categories: 0 | 1
  layout: string
  layoutType: '1x4' | '2x4'
}): IPostAndLayout[][] => {
  const cleanLayout = layout.replace('}', '').replace('{', '')
  const splitLayout = cleanLayout.split('|')

  const firstLayout =
    layoutType === '1x4'
      ? Matrix1x4LayoutMap[splitLayout[0]]
      : Matrix2x4LayoutMap[splitLayout[0]]

  const secondLayout =
    layoutType === '1x4'
      ? Matrix1x4LayoutMap[splitLayout[1]]
      : Matrix2x4LayoutMap[splitLayout[1]]

  if (!firstLayout || !secondLayout) {
    console.error(`Layout ${cleanLayout} is not supported yet 😔`)
  }

  if (layoutType === '2x4' && !matrixElements[0].posts.length) {
    console.error('Layout 2x4 requires at least one post in the first column')
    return []
  }

  const firstPostsGroup = matrixElements[0].posts.slice(0, firstLayout?.length)

  const secondPostsGroup = is_split_categories
    ? matrixElements[1].posts.slice(0, secondLayout.length)
    : matrixElements[0].posts.slice(
        firstLayout.length,
        firstLayout.length + secondLayout.length
      )

  const firstPostsInLayout = zipWith(
    firstPostsGroup,
    firstLayout,
    (post, layout) => ({ post, layout })
  )
  const secondPostsInLayout = zipWith(
    secondPostsGroup,
    secondLayout,
    (post, layout) => ({ post, layout })
  )

  return [firstPostsInLayout, secondPostsInLayout]
}

interface IPostAndLayout {
  post: IPost
  layout: IPostLayout
}

interface IPostLayout {
  rowSpan: number
  columnSpan: number
}

const Matrix1x4LayoutMap: { [key: string]: readonly IPostLayout[] } = {
  '1:1,2:1': [
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
  ],
  '1:2': [{ rowSpan: 1, columnSpan: 2 }],
} as const

const Matrix2x4LayoutMap: { [key: string]: readonly IPostLayout[] } = {
  '1:1,2:1,3:1,4:1': [
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
  ],

  '1:4': [{ rowSpan: 2, columnSpan: 2 }],

  '1:2,3:1,4:1': [
    { rowSpan: 1, columnSpan: 2 },
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
  ],

  '1:1,2:1,3:2': [
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 2 },
  ],

  '1:2,2:1,4:1': [
    { rowSpan: 2, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
  ],

  '1:1,2:2,3:1': [
    { rowSpan: 1, columnSpan: 1 },
    { rowSpan: 2, columnSpan: 1 },
    { rowSpan: 1, columnSpan: 1 },
  ],

  '1:2,2:2': [
    { rowSpan: 2, columnSpan: 1 },
    { rowSpan: 2, columnSpan: 1 },
  ],

  '1:2,3:2': [
    { rowSpan: 1, columnSpan: 2 },
    { rowSpan: 1, columnSpan: 2 },
  ],
} as const
