export const urlsEqual = (link: string, currentPath: string) => {
  if (link === currentPath) {
    return true
  }
  if (link?.startsWith('mailto:')) {
    return false
  }
  const newUrl = new URL(link, 'http://a.com')
  const { pathname: browserPath } = new URL(currentPath, newUrl.origin)
  const { pathname: linkPath } = newUrl

  const cleanBrowserPath =
    browserPath[browserPath.length - 1] === '/'
      ? browserPath.slice(0, -1)
      : browserPath
  const cleanLinkPath =
    linkPath[linkPath.length - 1] === '/' ? linkPath.slice(0, -1) : linkPath

  return cleanBrowserPath === cleanLinkPath
}

export const urlIncludes = (link: string, currentPath: string) => {
  if (link.startsWith('mailto:')) {
    return false
  }

  if (link === currentPath) {
    return true
  }
  const newUrl = new URL(link, 'http://a.com')
  const { pathname: browserPath } = new URL(currentPath, newUrl.origin)
  const { pathname: linkPath } = newUrl

  const cleanBrowserPath =
    browserPath[browserPath.length - 1] !== '/'
      ? browserPath + '/'
      : browserPath
  const cleanLinkPath = linkPath[linkPath.length - 1] !== '/' ? '/' : linkPath

  return cleanBrowserPath.includes(cleanLinkPath)
}
