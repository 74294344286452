import { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { playerConfig } from './constants'
import { utils } from './utils'
import * as gtag from './gtag'
import { prettyLog } from '../../../utils'
import { clientConfig } from '../../../client/clientConfig'

const { geo_cookie_name } = playerConfig

const defaultGeoLocationData = {
  country: 'Israel',
  ip: null,
}

const defaultCookie = () => {
  const cookies = new Cookies()
  const options = {
    path: '/',
    expires: new Date(
      new Date().getTime() + clientConfig.geo_ttl * 1000 * 60 * 60
    ),
  }
  cookies.set(
    geo_cookie_name,
    btoa(JSON.stringify(defaultGeoLocationData)),
    options
  )
}

export const useGeoPluginFetch = () => {
  const [geoLocationData, setGeoLocation] = useState(defaultGeoLocationData)

  useEffect(() => {
    const cookies = new Cookies()
    const geo_location_cookie = cookies.get(geo_cookie_name)
    if (geo_location_cookie) {
      const location = JSON.parse(atob(geo_location_cookie.replace(/=/g, '')))
      setGeoLocation({ country: location.country, ip: location.ip })
    } else {
      const geoApiUrl = `https://ssl.geoplugin.net/json.gp?k=${clientConfig.geo_plugin_key}`
      fetch(geoApiUrl)
        .then(
          response => {
            return response.json()
          },
          error => {
            prettyLog(
              'useFetch:62',
              ['geoplugin fetching data error:', error.message],
              'error'
            )
            gtag.fireEvents({
              category: 'errors',
              name: 'geoplugin error',
              label: error.message,
            })
            defaultCookie()
          }
        )
        .then(res => {
          const options = {
            path: '/',
            expires: new Date(
              new Date().getTime() + clientConfig.geo_ttl * 1000 * 60 * 60 //geo_cookie_days * 24 * 60 *
            ),
          }

          const cookies = new Cookies()
          if (res && !utils.isEmptyStr(res.geoplugin_countryName)) {
            cookies.set(
              geo_cookie_name,
              btoa(
                JSON.stringify({
                  country: res.geoplugin_countryName,
                  ip: res.geoplugin_request,
                })
              ),
              options
            )
            // cookies.set(btoa(geo_cookie_name), btoa(res.geoplugin_countryName), options);
            setGeoLocation({
              country: res.geoplugin_countryName,
              ip: res.geoplugin_request,
            })
          } else {
            defaultCookie()
          }
        })
    }
  }, [])
  return { ...geoLocationData }
}

export const toggleGeoLocation = () => {
  const cookies = new Cookies()
  const geo_location_cookie = cookies.get(geo_cookie_name)
  const location = JSON.parse(atob(geo_location_cookie.replace(/=/g, '')))
  const options = {
    path: '/',
    expires: new Date(
      new Date().getTime() + clientConfig.geo_ttl * 1000 * 60 * 60 //set for on day
    ),
  }
  cookies.set(
    geo_cookie_name,
    btoa(
      JSON.stringify({
        country: location.country === 'Israel' ? 'Not-Israel' : 'Israel',
        ip: location.ip,
      })
    ),
    options
  )
  window.location.reload()
}
